<template>
	<form
		@submit.prevent="submitForm"
		class="order-form _order-form"
	>
		<div class="order-form__head">
			<a @click="$router.back()">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M19 10.9999H7.135L10.768 6.63991C11.122 6.21591 11.064 5.58491 10.64 5.23191C10.215 4.87791 9.585 4.93591 9.232 5.35991L4.232 11.3599C4.193 11.4069 4.173 11.4619 4.144 11.5139C4.12 11.5559 4.091 11.5919 4.073 11.6379C4.028 11.7529 4.001 11.8739 4.001 11.9959C4.001 11.9969 4 11.9989 4 11.9999C4 12.0009 4.001 12.0029 4.001 12.0039C4.001 12.1259 4.028 12.2469 4.073 12.3619C4.091 12.4079 4.12 12.4439 4.144 12.4859C4.173 12.5379 4.193 12.5929 4.232 12.6399L9.232 18.6399C9.43 18.8769 9.714 18.9999 10 18.9999C10.226 18.9999 10.453 18.9239 10.64 18.7679C11.064 18.4149 11.122 17.7839 10.768 17.3599L7.135 12.9999H19C19.552 12.9999 20 12.5519 20 11.9999C20 11.4479 19.552 10.9999 19 10.9999"
						fill="#3D4248"
					/>
				</svg>
			</a>
			<h2 class="h2 order-form__head__h2">Оформление заказа</h2>
			<div class="order-form__date-order">Заказ на {{ $store.state.currentDate }}</div>
		</div>
		<div class="order-form__enumeration">
			<div class="order-form__enumeration__number">1</div>
			<h3 class="h3 order-form__enumeration__h3">Персональные данные</h3>
		</div>
		<OrderPersonal
			@changeFormProps="changeFormProps"
			@fieldsValidate="fieldsValidate"
		></OrderPersonal>
		<div class="order-form__enumeration">
			<div class="order-form__enumeration__number">2</div>
			<h3 class="h3 order-form__enumeration__h3">Доставка</h3>
		</div>
		<OrderDelivery
			@changeFormProps="changeFormProps"
			@changeDeliveryType="getOrderData"
		></OrderDelivery>
		<div class="order-form__enumeration">
			<div class="order-form__enumeration__number">3</div>
			<h3 class="h3 order-form__enumeration__h3">Способы оплаты</h3>
		</div>
		<OrderPayment
			ref="promo"
			@updateOrderData="getOrderData"
			:maxPoints="order.maxPoints"
			:allPoints="order.allPoints"
			:loyaltyAgreement="agreements.checkLoyaltyAgreement.value"
		>
		</OrderPayment>
		<BlockPreloader
			v-if="orderInfoLoading"
			class="order-form__preloader"
		/>
		<div
			class="order-form__summary"
			v-else
		>
			<div class="order-form__summary-row">
				<span class="order-form__summary-name">Сумма заказа</span>
				<span class="order-form__summary-value">{{ addThousandSeparator(order.fullSumm) }} ₽</span>
			</div>
			<div
				v-if="order.couponDiscount"
				class="order-form__summary-row"
			>
				<span class="order-form__summary-name">Скидка по купону</span>
				<span class="order-form__summary-value">-{{ addThousandSeparator(order.couponDiscount) }} ₽</span>
			</div>
			<div
				v-if="order.points"
				class="order-form__summary-row"
			>
				<span class="order-form__summary-name">Оплата баллами</span>
				<span class="order-form__summary-value">{{ addThousandSeparator(order.points) }} ₽</span>
			</div>
			<div
				v-if="currentPromocode"
				class="order-form__summary-row"
			>
				<span class="order-form__summary-name">Скидка по купону {{
						addThousandSeparator(currentPromocode)
					}}</span>
				<span class="order-form__summary-value">{{ addThousandSeparator(currentPromocodeDiscount) }} ₽</span>
			</div>
			<div class="order-form__summary-row order-form__summary-row--ogrn">
        		<span class="order-form__summary-name">
        			{{ restaurant.yur_name }}
        			<em>(ОГРН {{ restaurant.ogrn }}, ИНН {{ restaurant.inn }})</em>
        		</span>
				<span class="order-form__summary-value">{{
						showZeroSum ? 0 : addThousandSeparator(order.restaurantSumm)
					}} ₽</span>
			</div>
			<div class="order-form__summary-row order-form__summary-row--ogrn">
				<span class="order-form__summary-name">
					АО «Вкусно и быстро» <em>(ОГРН 2564578946554, ИНН 2564578946554)</em>
				</span>
				<span class="order-form__summary-value">{{ showZeroSum ? 0 : addThousandSeparator(order.serviceSumm) }} ₽</span>
			</div>
			<div
				v-if="order.addBalls"
				class="order-form__summary-row order-form__buy-balls"
			>
				<span class="order-form__summary-name">Баллов начислится:</span>
				<span class="order-form__summary-value">
					+{{ addThousandSeparator(order.addBalls) }}
					<img
						src="@/assets/img/icons/bonus.svg"
						alt="Bonus"
					>
				</span>
			</div>
			<div class="order-form__summary-row order-form__summary-row--bold">
				<span class="order-form__summary-name">Итого</span>
				<span class="order-form__summary-value">{{
						showZeroSum ? 0 : addThousandSeparator(order.summ - currentPromocodeDiscount)
					}} ₽</span>
			</div>
		</div>

		<div
			class="order-form__checkboxes"
			v-if="!this.$store.state.user"
		>
			<label
				class="checkbox-label g-input"
				:class="{'error':agreements.checkVibUserAgreement.error}"
			>
				<input
					type="checkbox"
					class="checkbox"
					v-model="agreements.checkVibUserAgreement.value"
					checked="true"
				>
				<span class="checkbox-icon"></span>
				<span class="checkbox-text">
					<span class="agree-text">Я ознакомлен и принимаю условия&nbsp;</span>
					<a
						v-if="!isMobile"
						href="/policies/offer-nao-vkusnoibistro/"
					>
						"Пользовательского соглашения АО "Вкусно и Быстро"
					</a>
          			<a
						v-else
						href="javascript:void(0);"
						@click="showTerms('agreement-vib')"
					>
          				"Пользовательского соглашения АО "Вкусно и Быстро"
          			</a>
        		</span>
			</label>
			<label
				class="checkbox-label g-input"
				:class="{'error':agreements.checkRstUserAgreement.error}"
			>
				<input
					type="checkbox"
					class="checkbox"
					v-model="agreements.checkRstUserAgreement.value"
					checked="true"
				>
				<span class="checkbox-icon"></span>
				<span class="checkbox-text">
					<span class="agree-text">Я ознакомлен и принимаю условия&nbsp;</span>
					<a
						v-if="!isMobile"
						href="/policies/offer-seller/"
					>"Пользовательского соглашения Продавца еды"</a>
          			<a
						v-else
						href="javascript:void(0);"
						@click="showTerms('agreement-seller')"
					>
          				"Пользовательского соглашения Продавца еды"
          			</a>
				</span>
			</label>
			<label
				class="checkbox-label g-input"
				:class="{'error':agreements.checkLoyaltyAgreement.error}"
			>
				<input
					type="checkbox"
					class="checkbox"
					v-model="agreements.checkLoyaltyAgreement.value"
					checked="true"
				>
				<span class="checkbox-icon"></span>
				<span class="checkbox-text">
					<span class="agree-text">Я ознакомлен и принимаю условия&nbsp;</span>
					<a
						v-if="!isMobile"
						href="/policies/offer-nao-suncoin/"
					>правила и условия программы лояльности</a>
					<a
						v-else
						href="javascript:void(0);"
						@click="showTerms('loyalty')"
					>
						правила и условия программы лояльности
					</a>
				</span>
			</label>
		</div>

		<button
			class="order-form__buy button button_red"
			:disabled="inProcess || payOrderClicked || orderInfoLoading"
			@click="handlePayOrder"
		>
			Оплатить заказ
		</button>

		<div
			class="order-form_agreement"
			v-if="!$store.state.user"
		>
			Нажимая кнопку &laquo;Оформить заказ&raquo;,
			я&nbsp;даю свое согласие на&nbsp;обработку моих персональных данных,
			в&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;27.07.2006 года
			&#8470;&nbsp;152-Ф3 &laquo;О&nbsp;персональных данных&raquo;, на&nbsp;условиях и&nbsp;
			для целей, определенных в&nbsp;Политике конфиденциальности
		</div>
		<Popup :visible.sync="isTermsModalVisible">
			<Terms
				:terms-text-mode="dataTermsTextMode"
				@close="isTermsModalVisible = false"
			/>
		</Popup>
	</form>
</template>

<script>
import OrderPersonal from "@/components/block/OrderPersonal.vue";
import OrderDelivery from "@/components/block/OrderDelivery.vue";
import OrderPayment from "@/components/block/OrderPayment.vue";
import Terms from "@/components/popup/Terms.vue";
import {useUserCompleteTheBuild, useVkRetargeting, useYandexMetrika, useTopMailCounter} from "@/composables";
import qs from "qs";
import {mapActions, mapGetters} from "vuex";
import {addThousandSeparator} from "@/helpers";
import BlockPreloader from "@/components/ui/BlockPreloader.vue";
import {notification} from "@/utils";

export default {
	setup()
	{
		const {yandexMetrikaGoal, ecommerceBuy} = useYandexMetrika();
		const {toggleInitiatorFinished} = useUserCompleteTheBuild();
		const {vkRetargetingPurchase, vkRetargetingInitiateCheckout} = useVkRetargeting();
		const {topMailCounterPurchase} = useTopMailCounter();

		return {
			yandexMetrikaGoal,
			ecommerceBuy,
			toggleInitiatorFinished,
			vkRetargetingPurchase,
			vkRetargetingInitiateCheckout,
			topMailCounterPurchase
		};
	},
	components: {OrderPersonal, OrderDelivery, OrderPayment, Terms, BlockPreloader},
	data()
	{
		return {
			payOrderClicked: true,
			orderInfoLoading: false,
			inProcess: false,
			result: null,
			agreements:
				{
					checkVibUserAgreement: {value: true, required: true, error: false},
					checkRstUserAgreement: {value: true, required: true, error: false},
					checkLoyaltyAgreement: {value: true, required: false, error: false},
				},
			order:
				{
					fullSumm: "",
					couponDiscount: "",
					points: "",
					restaurantSumm: "",
					serviceSumm: "",
					summ: "",
					addBalls: "",
					delivery: "",
				},
			propsForValid: {},
			restaurant: {name: "", ogrn: "", inn: ""},
			isTermsModalVisible: false,
			dataTermsTextMode: "",
			isMobile: !!process.env.VUE_APP_MOBILE,
		};
	},
	computed:
		{
			...mapGetters({
				currentDeliveryType: "order/currentDeliveryType",
				currentPromocode: "order/currentPromocode",
				currentPromocodeDiscount: "order/currentPromocodeDiscount",
				currentOrderInProcess: "order/currentOrderInProcess",
				showPaymentWidget: "order/getShowPaymentWidget",
				cartMeals: "getCartMeals",
				currentBasket: "getCurrentBasket"
			}),

			isValid()
			{
				return (this.propsForValid.city && this.propsForValid.address
					&& this.propsForValid.time && this.propsForValid.fio
					&& this.propsForValid.phone && this.propsForValid.email);
			},

			/** Если скидка купона равна 100%, вместо суммы в некоторых полях должны быть нули  */
			showZeroSum()
			{
				return this.order.summ - this.currentPromocodeDiscount <= 0;
			},
		},
	watch:
		{
			currentOrderInProcess()
			{
				if (!this.currentOrderInProcess)
					this.stopOrdering();
			}
		},
	methods:
		{
			...mapActions({
				sendSmsCode: "sendSmsCode",
				fetchRestDeliveryTypes: "restaurant/fetchRestDeliveryTypes"
			}),
			addThousandSeparator,
			fieldsValidate()
			{
				return this.$store.dispatch("order/validate");
			},
			async handlePayOrder()
			{
				//Отправляем уведомление для Yandex.Metrika
				if (this.$store.getters.currentDayStr === "today")
					await this.yandexMetrikaGoal(45209871, "order_paid_today");
				else
					await this.yandexMetrikaGoal(45209871, "order_paid_tomorrow");

				await this.yandexMetrikaGoal(45209871, "klick_oplatit_zakaz");
			},
			changeFormProps(formPropsObj)
			{
				for (let key in formPropsObj)
					this.$set(this.propsForValid, key, formPropsObj[key]);
			},

			async getOrderData(deliveryType)
			{
				this.orderInfoLoading = true;

				try
				{
					const params = qs.stringify({
						cartId: this.$store.state.cartId,
						day: this.$store.getters.currentDayStr,
						points: this.$store.state.order.points,
						promocode: this.$store.state.auth.promocode,
						deliveryType: deliveryType || this.currentDeliveryType,
						cartTypeId: this.$store.state.order.cartTypeId ? this.$store.state.order.cartTypeId : '',
					});

					const {data} = await this.$axios.post(`/cart/getOrder/`, params);

					if (!data.success)
					{
						notification({title: data.msg || "Произошла ошибка", type: "error"});
						return false;
					}

					for (let key in data.order)
						data.order[key] = Math.round(data.order[key] * 100) / 100;

					this.order = data.order;
					this.restaurant = data.restaurant;

					if (data.comment && this.$store.state.order.saveComment === "")
						this.$store.commit("order/setSaveComment", data.comment);

					if (data.order.cartTypeId)
						this.$store.commit("order/saveCartTypeId", data.order.cartTypeId);

					this.orderInfoLoading = false;

					return true;
				} catch (e)
				{
					throw new Error(e);
				}
			},

			isRequiredAgreementsChecked()
			{
				let allChecked = true;
				for (let agreement in this.agreements)
				{
					if (this.agreements[agreement].required === true && this.agreements[agreement].value === false)
					{
						allChecked = false;
						this.agreements[agreement].error = true;
					} else
						this.agreements[agreement].error = false;
				}
				return allChecked;
			},


			async submitForm()
			{

				if (this.inProcess) return;

				this.inProcess = true;

				let res = await this.$store.dispatch("order/validate");

				// проверка все ли галочки стоят
				let allChecked = false;
				if (this.$store.state.user)
					allChecked = true;
				else
					allChecked = this.isRequiredAgreementsChecked();

				if (!res || !allChecked)
				{
					this.inProcess = false;
					this.$cookie.delete("topay");
					this.payOrderClicked = false;
					this.$cookie.delete("orderInProcess");

					return this.scrollToErrors();
				}

				this.$store.commit('order/setOrderInProcess', true);

				let isLogged = await this.$store.dispatch("isLogged");

				if (isLogged)
				{
					//если не ввели ФИО или email берем их из тех что пришли с бэка, останавливаем оформление
					if
					(
						typeof this.$store.state.order.fio == "undefined" || this.$store.state.order.fio == ""
					)
					{
						this.$store.state.order.fio = this.$store.state.user.NAME;
						this.$store.state.order.email = this.$store.state.user.EMAIL;
						this.inProcess = false;
						this.$cookie.delete("topay");
						this.payOrderClicked = false;
						return this.scrollToErrors();
					}

					if (this.$cookie.get("topay"))
						this.$cookie.delete("topay");

					if (this.$cookie.get("orderInProcess"))
						await this.updateName();
				}

				this.$cookie.delete("orderInProcess");

				let result = await this.$store.dispatch("order/make");

				//получаем код ошибки и обрабатываем его.
				if (!result.data.success)
				{
					if (result.data?.action)
					{
						this.setTopayCookie();

						if (result.data.action === "loginBySmsCode")
						{
							const params = {phone: this.$store.state.order.phone};
							const {data} = await this.sendSmsCode(params);

							if (!data.success)
							{
								const title = data.msg || data.errors.phone[0] || "Неизвестная ошибка";
								return notification({title, type: "error"});
							}

							return this.$store.commit("openPhoneAuthPopup", {
								phone: this.$store.state.order.phone,
								step: 2
							});
						}
					} else
					{
						this.stopOrdering();
						return;
					}
				}


				// записываем в куки данные заказа
				let orderId = result.data.payUrl.match(/ORDER_ID=(\d+)/)[1];
				this.setTopayCookie(orderId);

				// Электронная комерция: Покупка
				if (this.cartMeals && orderId)
				{
					// преобразуем массив в нужный вид для электронной коммерции
					const products = this.cartMeals.map(meal => ({...meal.product, quantity: meal.count}));

					this.ecommerceBuy(products, orderId);
					this.vkRetargetingPurchase(products);
					this.topMailCounterPurchase();
				}

				let userAgent = navigator.userAgent.toLowerCase();

				if (userAgent.indexOf('firefox') == -1 && this.showPaymentWidget === 'Y')
				{
					this.$router.push('/payment');
				} else
				{
					let a = document.createElement('a');
					a.href = result.data.payUrl;
					a.click();
				}
			},
			stopOrdering()
			{
				this.$store.commit('order/setOrderInProcess', false);
				this.inProcess = false;
				this.payOrderClicked = false;
				this.$cookie.delete("topay");
			},
			setTopayCookie(orderId)
			{
				let topayData = this.$store.state.order;
				topayData["promocode"] = this.$store.state.auth.promocode;

				if (orderId)
					topayData["orderId"] = orderId;

				this.$cookie.set("topay", JSON.stringify(topayData), {expires: "10m"});
			},
			setOrderCookie()
			{
				this.$cookie.set("orderInProcess", true);
			},
			async updateName()
			{
				//	если нет имени на бэке обновляем там инфу
				if (!this.$store.state.user?.NAME || this.$store.state.user?.NAME == "undefined")
				{
					let resp = await this.$axios.post("/personal/getProfileInfo/");

					let data = qs.stringify({
						firstName: this.$store.state.order.fio,
						lastName: "",
						phone: `+7${resp.data.data.phone}`,
						email: resp.data.data.email,
					});

					await this.$axios.post("/personal/updateProfileInfo/", data);
				}
			},
			scrollToErrors()
			{
				setTimeout(() =>
				{
					let scrollPosY = 0,
						inputs = document.querySelectorAll(".g-input.error").length
							? document.querySelectorAll(".g-input.error")
							: document.querySelectorAll("input");

					scrollPosY = inputs[0].offsetTop - document.querySelector("header").offsetHeight;
					window.scrollTo(0, scrollPosY);
				}, 200);
			},
			async saveCommentToUser(comment)
			{
				let result = await this.$axios.post("/cart/setCommentToUser/", qs.stringify({"comment": comment}));
				return result.data.success;
			},
			/**
			 * Показывает попап с офертами
			 */
			showTerms(term)
			{
				this.dataTermsTextMode = term;
				this.isTermsModalVisible = true;
			},
			async handleInitiatorFinished(status)
			{
				if (this.currentBasket?.collective) await this.toggleInitiatorFinished(status);
			}
		},
	async mounted()
	{
		this.vkRetargetingInitiateCheckout();
		this.fieldsValidate();

		await this.fetchRestDeliveryTypes();

		if (this.currentBasket?.collective)
		{
			await this.handleInitiatorFinished(1);

			window.addEventListener("beforeunload", async () =>
			{
				await this.handleInitiatorFinished(0);
			});
		}

		document.addEventListener("keypress", (e) =>
		{
			if (e.code === "Enter")
				e.preventDefault();
		});

		if (this.$cookie.get("orderInProcess"))
		{
			this.$store.commit('order/setOrderInProcess', true);
			this.payOrderClicked = true;
		} else
		{
			this.payOrderClicked = false;
			this.inProcess = false;
		}
		await this.$store.dispatch("getCart");
		await this.getOrderData();

		if (localStorage.getItem("courierComment"))
		{
			let commentToSave = JSON.parse(localStorage.getItem("courierComment"));
			this.$store.commit("order/setSaveComment", commentToSave);
			await this.saveCommentToUser(commentToSave);
			localStorage.removeItem("courierComment");
		}

		if (this.$cookie.get("topay"))
		{
			let topayData = this.$cookie.get("topay");

			this.$cookie.delete("topay");

			topayData = JSON.parse(topayData);

			if (topayData["comment"].trim() === "" && topayData["saveComment"].trim() === "")
			{
				topayData["comment"] = this.$store.state.order["comment"];
				topayData["saveComment"] = this.$store.state.order["saveComment"];
			}

			for (let field in topayData)
			{
				if (topayData[field] !== "undefined" && topayData[field] !== "" && this.$refs.promo)
				{
					if (field === "promocode")
					{
						this.$store.state.auth.promocode = topayData[field];
						this.$refs.promo.showPromocode = true;
						this.$refs.promo.promocode = topayData[field];
					} else
						this.$store.state.order[field] = topayData[field];

					if (field === "points")
					{
						this.$refs.promo.showPoints = (topayData[field] > 0);
						this.$refs.promo.points = topayData[field];
					}
					if (field === "fio")
					{
						if (!this.$store.state.user?.NAME || this.$store.state.user?.NAME == "undefined")
							this.$store.state.user.NAME = topayData[field];
					}
				}
			}
		}
		if (this.$cookie.get("orderInProcess"))
		{
			this.submitForm();
			this.$cookie.delete("orderInProcess");
		}
	},
	async beforeDestroy()
	{
		await this.handleInitiatorFinished(0);
	},
};
</script>

<style lang="scss">
.checkbox-label.error .agree-text
{color: red;}

.order-form
{
	letter-spacing: .1px;

	.g-input__name
	{
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
		color: $textColor;
		opacity: 1;
	}

	&__preloader
	{
		background-color: white;
		height: 200px;
		margin-bottom: 20px;
		border-radius: 6px;
	}

	.g-input__sub
	{top: auto;}

	.g-input__input
	{
		background-color: $greyBackground;
		border-color: $greyDelimiter;

		display: flex;
		align-items: center;
		justify-content: space-between;

		input
		{
			width: 100%;
			margin-right: 24px;
			border: none;
			background-color: transparent;
		}

		img
		{
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
	}

	.checkbox-label .g-input__name
	{margin-bottom: 0;}
}

.order-form__head
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 8px;
	border-bottom: 1px solid $greyDelimiter;
	margin-bottom: 40px;
	padding-left: 3px;

	a
	{display: none;}
}

.order-form__head__h2
{
	font-size: 32px;
	line-height: 44px;
	font-family: "GoogleSans-Regular", sans-serif;
}

.order-form__enumeration
{
	display: flex;
	align-items: center;
	border-bottom: 1px solid #E5E5E7;
	margin-bottom: 32px;
	padding-bottom: 8px;
	gap: 12px;
}

.order-form__enumeration__number
{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	color: white;
	border-radius: 50%;
	background-color: $green;
}

.order-form__enumeration__h3
{
	font-size: 24px;
	line-height: 44px;
}

.order-form__date-order
{
	font-size: 18px;
	color: $greyLightText;
}

.order-form__summary
{
	margin-bottom: 30px;
}

.order-form__summary-row
{
	border-bottom: 1px dashed #66666650;
	margin-bottom: 16px;

	& > span
	{
		background-color: white;
		display: inline-block;
		position: relative;
		bottom: -4px;
	}

	em
	{
		font-size: 11px;
		line-height: 100%;
		padding-left: 4px;
		font-style: normal;
	}
}

.order-form__summary-row--bold
{
	font-family: "GoogleSans-Bold", sans-serif;

	.order-form__summary-name
	{
		font-size: 18px;
		font-weight: 700;
		line-height: 32px;
	}

	.order-form__summary-value
	{
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
	}
}

.order-form__summary-name
{
	color: #3d4248;
	padding-right: 5px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;

	em
	{
		font-size: 12px;
		color: $greyLightText;
	}
}

.order-form__summary-value
{
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	float: right;
	padding-left: 10px;
}

.order-form__checkboxes
{
	margin-bottom: 30px;

	a
	{
		color: #40ae49;
		text-decoration: underline;
		font-weight: bold;
		padding-left: 4px;

		&:hover
		{text-decoration: none;}
	}

	.checkbox-label
	{margin-bottom: 15px;}

	.checkbox-text
	{
		font-size: 14px;
		color: #747678;
	}

	.checkbox-text a
	{font-weight: 400;}
}

.order-form__buy
{
	border-radius: 8px;
	margin-bottom: 30px;
	font-family: $mainFontBold;
	font-size: 18px;
	width: 100%;
	height: 50px;
}

.order-form__buy-balls
{
	.order-form__summary-name
	{color: $green;}

	.order-form__summary-value
	{
		display: flex;
		align-items: center;
		background-color: $green;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		color: white;
		padding: 2px 4px;
		align-items: center;
		border-radius: 4px;

		img
		{
			width: 18px;
			height: 18px;
			margin-left: 4px;
		}
	}
}

.order-form_agreement
{
	color: $greyLightText;
	font-size: 13px;
	font-weight: 400;
	line-height: 140%;
}

@media (max-width: 767px)
{
	.order-form__head
	{
		gap: 8px;
		margin-bottom: 18px;
		justify-content: flex-start;
		padding: 0;
		border: none;

		a
		{display: block;}
	}
	.order-form__head__h2
	{
		font-size: 22px;
		margin-bottom: 5px;
	}
	.order-form__enumeration
	{
		margin-bottom: 16px;
		padding-bottom: 14px;
	}
	.order-form__enumeration__h3
	{
		font-size: 20px;
		line-height: 28px;
	}
	.order-form__date-order
	{display: none}
	.order-form__checkboxes .checkbox-text
	{
		display: block;
		font-size: 12px;
		line-height: 13.2px;
	}
	.order-form__summary-row--ogrn
	{margin-bottom: 32px}
	.order-form__summary-row em
	{
		position: absolute;
		width: 300px;
		bottom: -15px;
		left: 0;
	}
	.order-form__summary-row
	{
		display: flex;
		position: relative;
		justify-content: space-between;
	}
	.order-form__summary-name
	{line-height: 16px;}
	.order-form__summary-value
	{
		text-align: right;
		padding-left: 3px;
		white-space: nowrap;
	}
}
</style>
