<template>
	<input
		v-maska="phoneMask"
		type="tel"
		ref="phoneInput"
		v-bind:value="valuePhone"
		@input="updateValue"
		:placeholder="placeholderText"
		:id="id"
		@change="$emit('change')"
	>
</template>

<script>
import qs from 'qs';
import Validator from '@/mixins/validator.js';
import {maska} from 'maska'

export default {
	directives: {maska},
	$_veeValidate: {
		value()
		{
			return this.valuePhone;
		}
	},
	inheritAttrs: true,
	mixins: [Validator],
	props: ['valuePhone', 'placeholderText', 'id'],
	model: {
		prop: 'inputValue',
		event: 'input'
	},
	data()
	{
		return {
			inputValue: this.valuePhone,
			phoneMask: {
				mask: '+7 (T##) ###-##-##',
				tokens: {'T': {pattern: /[0-9]/, transform: (char) => char !== '8' ? char : ''}},
			},
			error: '',
		}
	},
	methods: {
		updateValue(e)
		{
			let value = e.target.value;
			this.$emit('input', value);
		},
	},
}
</script>