<template>
	<div class="address-alert-wr">
		<div class="address-alert">
			<div class="address-alert__title">
				При смене адреса доставки заказ может потребоваться собрать заново
			</div>
			<div class="address-alert__bottom">
				<button
					class="button address-alert__btn address-alert__btn-change"
					@click="allowChangeAddress(); close();"
				>Изменить адрес
				</button>
				<button
					class="button button_red address-alert__btn address-alert__btn-cancel"
					@click="close"
				>Отменить
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	methods:
		{
			/**
			 * close popup
			 */
			close()
			{
				this.$parent.close();
			},
			allowChangeAddress()
			{
				this.$emit('allowchangeaddress');
			}
		}
}
</script>

<style lang="scss">
.address-alert-wr
{
	width: 1200px;
}

.address-alert
{
	width: 640px;
	margin: 0 auto;
	padding-top: 54px;
	padding-bottom: 45px;
	letter-spacing: .1px;
}

.address-alert__title
{
	font-family: $mainFontBold;
	font-size: 28px;
	line-height: 120%;
	text-align: center;
	color: $textColor;
	margin-bottom: 29px;
}

.address-alert__bottom
{
	padding: 0 67px;
	display: flex;
	justify-content: space-between;
}

.address-alert__btn
{
	font-family: $mainFontBold;
	height: 50px;
	font-size: 16px;
}

.address-alert__btn-change
{width: 260px; }

.address-alert__btn-cancel
{width: 220px; }

@media (max-width: 767px)
{
	.address-alert-wr,
	.address-alert
	{
		max-width: 100vw;
	}
	.address-alert__title
	{
		font-size: 22px;
	}
	.address-alert__bottom
	{
		flex-direction: column;
		padding: 0;
	}
	.address-alert__btn
	{
		margin: 0 auto;

		&:not(:last-child)
		{
			margin-bottom: 20px;
		}
	}
	.address-alert__btn-cancel
	{
		width: 260px;
	}
}
</style>