<template>
	<div class="order-summary">
		<div class="order-summary-row-label">
			<div class="order-summary-row-label__left">
				<div class="order-summary-row-label__item">Наименование:</div>
			</div>
			<div class="order-summary-row-label__right">
				<div class="order-summary-row-label__item">Кол-во</div>
				<div class="order-summary-row-label__item">Итого:</div>
			</div>
		</div>
		<div
			class="order-summary-row order-summary-row__lunch"
			v-for="lunch in this.$store.state.cartLanches"
		>
			<div class="order-summary-row__left">
				<div class="order-summary-row__img">
					<img
						src="@/assets/img/cartLunch.webp"
						alt=""
					>
				</div>
				<div class="order-summary-row__descr">
					<div
						class="order-summary-row__title"
						v-html="lunch.name"
					></div>
					<div
						class="order-summary-row__type"
						v-html="lunch.lanch.name"
					></div>
				</div>
			</div>
			<div class="order-summary-row__right">
				<div class="order-summary-row__number">{{ lunch.count }} шт.</div>
				<div class="order-summary-row__value">
					<span>{{ lunch.lanch.price * lunch.count }}</span>
				</div>
			</div>
		</div>
		<div
			class="order-summary-row"
			v-for="meal in this.$store.state.cartMeals"
		>
			<div class="order-summary-row__left">
				<div class="order-summary-row__img">
					<img
						:src="meal.product.preview_picture.thumb"
						alt=""
					>
				</div>
				<div class="order-summary-row__descr">
					<div
						class="order-summary-row__title"
						v-html="meal.product.name"
					></div>
					<div
						class="order-summary-row__type"
						v-html="meal.product.type.name"
					></div>
				</div>
			</div>
			<div class="order-summary-row__right">
				<div class="order-summary-row__number">{{ meal.count }} шт.</div>
				<div class="order-summary-row__value">
					<span>{{ meal.product.price * meal.count }}</span>
				</div>
			</div>
		</div>
		<div
			class="order-summary-row"
			v-for="gift in $store.state.cartGifts"
		>
			<div v-if="gift.count > 0">
				<div class="order-summary-row__left">
					<div class="order-summary-row__img">
						<img
							:src="gift.product.preview_picture.thumb"
							alt=""
						>
					</div>
					<div class="order-summary-row__descr">
						<div
							class="order-summary-row__title"
							v-html="gift.product.name"
						></div>
						<div
							class="order-summary-row__type"
							v-html="gift.product.type.name"
						></div>
					</div>
				</div>
				<div class="order-summary-row__right">
					<div class="order-summary-row__number">{{ gift.count }} шт.</div>
					<div class="order-summary-row__value">
						<span>0</span>
					</div>
				</div>
				<div class="order-summary-row__gift">
					<svg
						fill="transparent"
						width="40"
						height="40"
					>
						<use xlink:href="#cart-row__gift"></use>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'OrderSummary'
}
</script>
<style lang="scss">
.order-summary
{
	background-color: #fff;
	border-radius: 10px;
	width: 100%;
	padding: 41px 35px 24px;
	letter-spacing: .1px;
}

.order-summary-row
{
	position: relative;
	background-image: linear-gradient(to right, $textColor 20%, rgba(61, 66, 72, 0) 0%);
	background-position: bottom;
	background-size: 5px 1px;
	background-repeat: repeat-x;
	display: flex;
	padding-bottom: 20px;
	align-items: center;

	&:not(:last-child)
	{
		margin-bottom: 20px;
	}

	&:last-child
	{
		background-image: none;
	}
}

.order-summary-row__left, .order-summary-row__right
{
	display: flex;
}

.order-summary-row-label
{
	display: flex;
	margin-bottom: 21px;
}

.order-summary-row-label__item
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 17px;
	color: #666;
	opacity: 0.7;
}

.order-summary-row-label__right
{
	.order-summary-row-label__item
	{
		margin-right: 37px;
	}
}

.order-summary-row__left, .order-summary-row-label__left
{
	align-items: center;
	display: flex;
	width: 100px;
	width: 345px;
}

.order-summary-row__right, .order-summary-row-label__right
{
	align-items: center;
	display: flex;
}

.order-summary-row__item
{
	font-family: $mainFont;
	font-size: 14px;
	line-height: 17px;
	color: #666;
	opacity: 0.7;
}

.order-summary-row__img
{
	width: 85px;
	height: 78px;
	margin-right: 30px;
	margin-left: 4px;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover
	}
}

.order-summary-row__descr
{
	padding-bottom: 2px;
	max-width: 210px;
	width: 100%;
}

.order-summary-row__title
{
	font-family: $mainFontBold;
	font-size: 16px;
	line-height: 100%;
	color: $textColor;
	padding-bottom: 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.order-summary-row__type
{
	font-family: $mainFont;
	font-size: 13px;
	line-height: 16px;
	color: #666;
	opacity: 0.7;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.order-summary-row__number
{
	font-family: $mainFontBold;
	font-size: 16px;
	line-height: 20px;
	color: $textColor;
	width: 60px;
}

.order-summary-row__value
{
	font-family: $mainFontBold;
	font-size: 24px;
	line-height: 29px;
	color: $textColor;
	width: 96px;
	display: flex;
	justify-content: flex-end;

	&:after
	{
		content: '₽';
		font-size: 16px;
		padding-left: 6px;
		align-self: flex-start;
	}
}

.order-summary-row__gift
{
	position: absolute;
	right: 50px;
	top: 30px;
}
</style>