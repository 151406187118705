<template>
	<div class="order-wr">
		<div class="order">
			<div class="order-form-wr">
				<OrderForm/>
			</div>
			<div
				class="order-summary-wr"
				v-if="false"
			>
				<h3 class="h3 order-summary-wr__h3">Ваш заказ</h3>
				<OrderSummary/>
			</div>
		</div>
	</div>
</template>

<script>
import OrderForm from '@/components/form/OrderForm.vue';
import OrderSummary from '@/components/block/OrderSummary.vue';
import qs from 'qs';

export default {
	components: {OrderForm, OrderSummary},
	async mounted()
	{
		await this.$store.dispatch('getCart')

		if (!this.$store.state.cartLanches.length && !this.$store.state.cartMeals.length)
			this.$router.push('/cart/');
	},
}
</script>
<style lang="scss">
.order-wr
{width: 100%; }

.order
{
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.order-summary-wr
{
	width: 592px;
	margin-top: 71px;
}

.order-summary-wr__h3
{
	font-family: $mainFontBold;
	font-size: 22px;
	line-height: 100%;
	color: $textColor;
	letter-spacing: .1px;
	margin-bottom: 29px;
}

@media (max-width: 1544px)
{
	.order
	{flex-direction: column-reverse; }
	.order-summary-wr
	{
		margin-top: 0;
		margin-bottom: 50px;
	}
}

@media (max-width: 767px)
{
	.order-summary-wr
	{display: none; }
}
</style>
