<template>
	<div class="order-personal">
		<label
			class="g-input"
			:class="{'error':$store.state.order.fioError}"
		>
			<span class="g-input__name">Ваше имя:</span>
			<div class="g-input__input">
				<input
					name="something1"
					type="text"
					v-model="getFio"
					@change="changeData(); fieldValidate()"
				>
				<img
					v-if="getFio"
					src="@/assets/img/close.svg"
					alt="Close"
					@click="clearField('fio')"
				>
			</div>
			<div class="g-input__sub">{{ $store.state.order.fioError }}</div>
		</label>

		<label
			class="g-input"
			:class="{'error':$store.state.order.phoneError}"
		>
			<span class="g-input__name">Телефон:</span>
			<div class="g-input__input">
				<PhoneInput
					v-model="getPhone"
					@input="changeData"
					:valuePhone="getPhone"
					@change="fieldValidate"
				/>
				<img
					v-if="getPhone"
					src="@/assets/img/close.svg"
					alt="Close"
					@click="clearField('phone')"
				>
			</div>
			<div class="g-input__sub">{{ $store.state.order.phoneError }}</div>
		</label>

		<label
			class="g-input"
			:class="{'error':$store.state.order.emailError}"
		>
			<span class="g-input__name">E-mail (сюда пришлем чек об оплате):</span>
			<div class="g-input__input">
				<input
					name="something2"
					type="text"
					v-model="getEmail"
					@change="changeData(); fieldValidate()"
				>
				<img
					v-if="getEmail"
					src="@/assets/img/close.svg"
					alt="Close"
					@click="clearField('email')"
				>
			</div>
			<div class="g-input__sub">{{ $store.state.order.emailError }}</div>
		</label>
	</div>
</template>
<script>
import PhoneInput from '@/components/form/PhoneInput.vue';

export default {
	components: {PhoneInput},
	data()
	{
		return {
			personal:
				{
					fio: '',
					phone: '',
					email: ''
				},
			emailIsDirty: false
		}
	},
	watch:
		{
			personal:
				{
					handler()
					{
						this.personal.email = this.personal.email.trim();
						this.$emit('changeFormProps', this.personal);
					},
					deep: true
				}
		},
	computed:
		{
			getFio:
				{
					get: function ()
					{
						return this.personal.fio;
					},
					set: function (newValue)
					{
						this.personal.fio = newValue
					}
				},
			getPhone: {
				get: function ()
				{
					let phone = this.personal.phone;

					phone = phone ? phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 $1 $2-$3-$4') : '+7 ';

					return phone;
				},

				set: function (newValue)
				{
					this.personal.phone = newValue
				}
			},
			getEmail:
				{
					get: function ()
					{
						return this.personal.email.trim();
					},
					set: function (newValue)
					{
						this.emailIsDirty = true;
						this.personal.email = newValue.trim();
					}
				},
		},
	methods:
		{
			fieldValidate()
			{
				this.$emit('fieldsValidate');
			},
			changeData()
			{
				this.$store.commit('order/setPersonal', {
					fio: this.personal.fio,
					phone: this.personal.phone?.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 $1 $2-$3-$4') || '',
					email: this.personal.email.trim()
				})
			},
			clearField(personalKey)
			{
				this.$set(this.personal, personalKey, '');
				this.changeData();
			}
		},

	mounted()
	{
		this.personal.fio = this.$store.state.user.NAME || '';
		this.personal.phone = this.$store.state.user.PERSONAL_PHONE || '';
		this.personal.email = this.$store.state.user.EMAIL || '';
		this.changeData();
	}
}
</script>
<style lang="scss">
.order-personal
{
	display: grid;
	grid-template-columns: 400px 400px;
	grid-template-rows: auto;
	grid-gap: 25px;
	margin-bottom: 35px;

	.g-input__input
	{height: 52px;}
}

@media (max-width: 900px)
{
	.order-personal
	{
		grid-template-columns: auto;
		grid-template-rows: auto;
		grid-gap: 25px;
	}
}
</style>
