<template>
	<div class="order-pickup">
		<span class="order-pickup__title">{{ restaurant.name }}</span>
		<p class="order-pickup__address">{{
				(restaurant.city ? 'г. ' + restaurant.city[Object.keys(restaurant.city)[0]] +
					', ' : '') + restaurant.address
			}}</p>
		<div class="order-pickup__work-time">
			<span class="order-pickup__mode">Режим работы:</span>
			<span class="order-pickup__time">{{ restaurant.worktime ? restaurant.worktime[0] : '' }}</span>
		</div>
		<div class="order-pickup__geomark"></div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: ['restaurant'],
}
</script>

<style lang="scss">
.order-pickup
{
	padding: 24px;
	border: 2px solid $green;
	max-width: 437px;
	position: relative;
	width: 100%;
	border-radius: 8px;
	font-family: "Montserrat-Semi", sans-serif;
	margin-bottom: 20px;

	&__title
	{
		color: $green;
		font-size: 16px;
		margin-bottom: 5px;
		font-weight: bold;
	}

	&__address
	{
		font-size: 14px;
		color: $textColor;
		font-weight: 500;
		margin-bottom: 16px;
	}

	&__mode
	{
		color: $greyLightText;
		margin-right: 10px;
		font-size: 14px;
	}

	&__time
	{
		font-size: 14px;
		color: $textColor;
	}

	&__geomark
	{
		position: absolute;
		height: 24px;
		width: 24px;
		top: 24px;
		right: 24px;
		background: url("~@/assets/img/geomark.svg") center;
		background-size: cover;
	}
}
</style>
